import classNames from "classnames";
import type { ReactNode } from "react";
import "./Panel.css";

export interface PanelProps {
  className?: string;
  children: ReactNode;
}

export default function Panel(props: PanelProps) {
  const { className: _className, children } = props;

  const className = classNames("panel", _className);

  return <div className={className}>{children}</div>;
}
