import type { PageId, PageMap } from "@mrs/common";
import { readPageProperty, siteMapProperties } from "@mrs/common";
import classNames from "classnames";
import lodash from "lodash";
import { useMemo, type ReactElement } from "react";
import Link from "./Link.js";
import Panel from "./Panel.js";
import Heading from "./Heading.js";
import Spaced from "./Spaced.js";

export interface SiteMapPanelProps {
  pageMap: PageMap;
}

export function SiteMapPanel(props: SiteMapPanelProps): ReactElement {
  const { pageMap } = props;

  const pages = usePageNodes(pageMap);

  return (
    <Panel>
      <Spaced spacing="4">
        <Heading level="subsubsection">Pages</Heading>
        <PageList pages={pages} parentId={null} />
      </Spaced>
    </Panel>
  );
}

interface PageNode {
  pageId: PageId;
  title: string;
  path: string;
  order: number;
  parentId: PageId | null;
}

function usePageNodes(pageMap: PageMap): PageNode[] {
  return useMemo<PageNode[]>(() => {
    return lodash
      .chain(pageMap)
      .flatMap(({ pageId, title, path, properties }) => {
        const parents =
          readPageProperty(properties, siteMapProperties.parent) ?? [];

        const parentId = parents.length === 0 ? null : parents[0];

        const order = readPageProperty(properties, siteMapProperties.order);

        return order == null
          ? []
          : [
              {
                pageId,
                title,
                path,
                order,
                parentId,
              },
            ];
      })
      .sortBy(page => page.order)
      .value();
  }, [pageMap]);
}

interface PageListProps {
  parentId: PageId | null;
  pages: PageNode[];
}

function PageList(props: PageListProps) {
  const { parentId, pages } = props;

  const selected = useMemo(
    () => pages.filter(page => page.parentId === parentId),
    [pages, parentId]
  );

  const ulClassName = classNames(
    "flex flex-col gap-2 text-sm",
    parentId == null ? null : "pl-4"
  );

  return selected.length === 0 ? null : (
    <ul className={ulClassName}>
      {selected.map(page => (
        <li key={page.pageId} className="flex flex-col gap-2">
          <Link.Internal className="block" to={page.path}>
            {page.title}
          </Link.Internal>
          <PageList parentId={page.pageId} pages={pages} />
        </li>
      ))}
    </ul>
  );
}
